<template>
    <div class="appointment my-container">
        <van-nav-bar title="实验室预约" safe-area-inset-top  left-text="返回" left-arrow   @click-left="$router.go(-1)"/> 
        <router-view v-model="FormData"></router-view>
    </div>
</template>
<script>
import { NavBar ,Dialog  } from 'vant';
export default {
    components:{
        [NavBar.name]: NavBar,
        [Dialog.name]: Dialog,
    },
   
    data() {
        return {
            FormData:{
                LA_ApprovalName              :'',
                LA_IName                     :'',
                LA_ProjectName               :'',
                LA_Instructor                :'',
                TimeList                     :{},
                LA_DangerousGoods            :0,
                LA_DAID                      :'',
                LA_DAPhontos                 :'',
                LA_PersonnelInformation      :'',
                LA_EnvironmentalRequirements :'',
                LA_PersonLiable              :'',
                LA_PersonLiablePost          :'',
                LA_EquipmentUsageTime        :'',
                LA_EquipmentNum              :'',
                LA_ReagentClassification     :'',
                LA_QuantityOfReagents        :'',
                LA_PackagingRequirements     :'',
                LA_EquipmentName             :'',
                LA_SpecialRequirements       :'',
            },
            OldFormData:'',
        }
    },
    created() {
        this.OldFormData = JSON.stringify(this.FormData);//保存起初默认值
        if(sessionStorage.getItem('KeepLive')){
            if(sessionStorage.getItem('KeepLive') !== this.OldFormData){
                // 数据有改变
                this.$dialog.confirm({
                    title: '提示',
                    getContainer:this.getContainer,
                    message: '检测有一条预约信息尚未提交，是否恢复表单信息',
                })
                .then(() => {
                    // on confirm
                    this.FormData = JSON.parse(sessionStorage.getItem('KeepLive'))
                    sessionStorage.removeItem('KeepLive')
                })
                .catch(() => {
                    sessionStorage.removeItem('KeepLive')
                    this.$router.replace('/makeAppointment/laboratoryInfo')
                });
            }else {

            }
        }
    },
    mounted(){
        // setTimeout(()=>{
        //    this.FormData.LA_Time = {
        //         '2021-01-20' :['0','1','2','3'],
        //         '2021-01-21' :['4','5','6','7'],
        //     }
        // },1000)
        window.addEventListener('pagehide',this.beforeunloadHandler)
    },
    methods:{
         getContainer() {
            return document.querySelector('.my-container');
        },
        beforeunloadHandler(){
            let Flag =  JSON.stringify(this.FormData)

            sessionStorage.setItem('KeepLive',Flag)
        },
    },
    destroyed() {
        window.removeEventListener('pagehide',this.beforeunloadHandler)  
        sessionStorage.removeItem('KeepLive')
    }
}
</script>
<style lang="scss" scoped>
.appointment {
    display: flex;
    flex-direction: column;
    height: 100vh;
    /deep/ .van-dialog__confirm {
        color: #ee0a24;
        border-color: transparent;
    }
}
</style>